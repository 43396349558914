import axios from 'axios';
import { 
    API_URL,
    APP_API_URL,
    GET_DEFINITION_DETAILS_INFO_REQUEST,
    GET_DEFINITION_DETAILS_INFO_SUCCESS,
    GET_DEFINITION_DETAILS_INFO_FAILURE,
    TOGGLE_VALIDATION_SLIDER,
    GET_TOTAL_RECORDS_REQUEST,
    GET_TOTAL_RECORDS_SUCCESS,
    GET_TOTAL_RECORDS_FAILURE,
    GET_ROWS_BY_RANGE_REQUEST,
    GET_ROWS_BY_RANGE_SUCCESS,
    GET_ROWS_BY_RANGE_FAILURE,
    SELECT_CELL_FROM_TABLE,
    LOAD_TABLE_DATA_REQUEST,
    LOAD_TABLE_DATA_SUCCESS,
    LOAD_TABLE_DATA_FAILURE,
    EDIT_CELL_VALUE,
    EDIT_CELL_FROM_SLIDER,
    VALIDATE_CELL_REQUEST,
    VALIDATE_CELL_SUCCESS,
    VALIDATE_CELL_FAILURE,
    DOWNLOAD_DEFINITION_REQUEST,
    DOWNLOAD_DEFINITION_SUCCESS,
    DOWNLOAD_DEFINITION_FAILURE,
    CLEAR_SLECTED_ROW,
    GET_UPLOADED_FILES_LIST_REQUEST,
    GET_UPLOADED_FILES_LIST_SUCCESS,
    GET_UPLOADED_FILES_LIST_FAILURE,
    MAKE_FILE_ATTRIBUTES_EDITABLE,
    UPLOADED_FILE_ATTR_EDIT,
    UPLOADED_FILE_UPDATE_REQUEST,
    UPLOADED_FILE_UPDATE_SUCCESS,
    UPLOADED_FILE_UPDATE_FAILURE,
    CANCEL_UPLOADED_FILE_UPDATE,
    UPLOADED_FILE_DELETE_REQUEST,
    UPLOADED_FILE_DELETE_SUCCESS,
    UPLOADED_FILE_DELETE_FAILURE,
    UPLOADED_FILE_DOWNLOAD_REQUEST,
    UPLOADED_FILE_DOWNLOAD_SUCCESS,
    UPLOADED_FILE_DOWNLOAD_FAILURE,
    GET_SUPPORTED_ENCODINGS_REQUEST,
    GET_SUPPORTED_ENCODINGS_SUCCESS,
    GET_SUPPORTED_ENCODINGS_FAILURE,
    DEFINE_FILE_DEFINITION,
    GET_AN_IMPORT_LOG_REQUEST,
    GET_AN_IMPORT_LOG_SUCCESS,
    GET_AN_IMPORT_LOG_FAILURE
} from '../../../shared/constants';

import { toast } from 'react-toastify';
import { BlobServiceClient } from '@azure/storage-blob';
//var fileDownload = require('js-file-download');
import { WritableStream } from 'web-streams-polyfill/ponyfill';
import streamSaver from 'streamsaver';

export const demo= () => {
    return {
        type: DEFINE_FILE_DEFINITION
    }
}

export const getDefinitionInfo= id => {
    // const definitionDetails= [
    //     {
    //         fileDefinitionId: 1,
    //         projectName: "Supplier Enablement",
    //         fileDefinitionName: "Invoices",
    //         statistic1: 63,
    //         statistic2: 80,
    //         statistic3: 20,
    //         inputFormat: 'csv',
    //         outputFormat: 'csv'
    //     }, {
    //         fileDefinitionId: 30,
    //         projectName: "Spend Vis - Q4 Data Loads",
    //         statistic1: 77,
    //         statistic2: 44,
    //         statistic3: 37,
    //         inputFormat: 'csv',
    //         outputFormat: 'csv'
    //     }
    // ];
        
    // const data= definitionDetails.filter(item => item.fileDefinitionId === parseInt(id))[0];
    // console.log("details data = ", data);

    const request = () => ({ type: GET_DEFINITION_DETAILS_INFO_REQUEST });
    const success = (data) => ({ type: GET_DEFINITION_DETAILS_INFO_SUCCESS, data });
    const failure = (error) => ({ type: GET_DEFINITION_DETAILS_INFO_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/detail/${id}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };

    // if(data) {
    //     return {
    //         type: GET_DEFINITION_DETAILS_INFO_SUCCESS,
    //         projectDefinition: data
    //     }
    // } else {
    //     return {
    //         type: GET_DEFINITION_DETAILS_INFO_FAILURE
    //     }

    // }
}

export const toggleValidationSlider = (tabId, rowId) => {
    return {
        type: TOGGLE_VALIDATION_SLIDER,
        tabId: tabId,
        rowId: rowId
    }
}

export const getTotalRecordsCount = projectDefinitionId => {
    const request = () => ({ type: GET_TOTAL_RECORDS_REQUEST });
    const success = (data) => ({ type: GET_TOTAL_RECORDS_SUCCESS, data });
    const failure = (error) => ({ type: GET_TOTAL_RECORDS_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/getRowCount/${projectDefinitionId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}

// export const getRowsByRange = (projectDefinitionId, fromRowNumber, toRowNumber) => {
//     const request = () => ({ type: GET_ROWS_BY_RANGE_REQUEST });
//     const success = (data) => ({ type: GET_ROWS_BY_RANGE_SUCCESS, data });
//     const failure = (error) => ({ type: GET_ROWS_BY_RANGE_FAILURE, error });

//     return dispatch => {
//         dispatch(request());
//         const token= JSON.parse(sessionStorage.user).data.access_token
//         axios({
//             url: `${APP_API_URL}/CDT/secured/api/fileDefinition/getTableRowsByRange/${projectDefinitionId}?fromRowNumber=${fromRowNumber}&toRowNumber=${toRowNumber}`,
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`
//             }
//         })
//         .then(function (response) {
//             if (response.status === 200) {
//                 dispatch(success(response.data));
//                 console.log("Rows by range= ", JSON.stringify(response.data));
//                 debugger
//             }
//         })
//         .catch(function (error) {
//             console.log("error projects");
//             dispatch(failure(error));   // network failure
//         });

//     };
// }

export const selectCellFromTable= (row, cell, index, selectedFilter, pageSize) => {
    return {
        type: SELECT_CELL_FROM_TABLE,
        row: row,
        cell: cell,
        rowIndex: index,
        selectedFilter: selectedFilter,
        pageSize: pageSize
    }
}

export const loadTableData= (projectDefinitionId, currentPageNumber, pageSize, selectedFilter, selectedRowId, isReset) => {
    const request = () => ({ type: LOAD_TABLE_DATA_REQUEST, isReset: isReset });
    const success = (data) => ({ type: LOAD_TABLE_DATA_SUCCESS, data });
    const failure = (error) => ({ type: LOAD_TABLE_DATA_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/getTableRowsByRangeAndFilter/${projectDefinitionId}?currentPage=${currentPageNumber}&currentRowId=${selectedRowId || ''}&filter=${selectedFilter}&pageSize=${pageSize}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("Rows by range and filter= ", JSON.stringify(response.data));
                if(response.data) {
                    dispatch(success({
                        rows: [...response.data.rows],
                        cols: [...response.data.headerColumns],
                        dataStats: {...response.data.dataStats},
                        currentPage: {...response.data.currentPage},
                        selectedFilter: selectedFilter
                    }));
                }
            }
        })
        .catch(function (error) {
            console.log("error load table", error);
            dispatch(failure(error));
            toast.error(error);
        });
    };
}

export const editCell= (newValue, colIndex, rowId) => {
    return {
        type: EDIT_CELL_VALUE,
        newValue: newValue,
        colIndex: colIndex,
        rowId: rowId
    }
}

export const editCellFromSlider= newValue => {
    return {
        type: EDIT_CELL_FROM_SLIDER,
        newValue: newValue
    }
}

export const validateCell= (projectDefinitionId, colIndex, rowId, value, selectedFrontEndFilter, selectedPageSize, onValidationOperation) => {
    const request = () => ({ type: VALIDATE_CELL_REQUEST });
    const success = (data) => ({ type: VALIDATE_CELL_SUCCESS, data });
    const failure = (error) => ({ type: VALIDATE_CELL_FAILURE, error });
    const formData= {
        "colIndex": colIndex,
        "rowId": rowId,
        "value": value
    };

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/updateCellAndRevalidateRowsInPage/${projectDefinitionId}?filter=${selectedFrontEndFilter}&pageSize=${selectedPageSize}`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: formData
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("validate cell response= ", JSON.stringify(response.data));
                dispatch(success({
                    rows: [...response.data.rows],
                    cols: [...response.data.headerColumns],
                    dataStats: {...response.data.dataStats},
                    currentPage: {...response.data.currentPage},
                    onValidationOperation: onValidationOperation
                }));
                toast.success("Updated and validated successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("validate cell error1", error);
        });

    };
}

export const downloadDefinitionData = (fileDefinition, selectedFilter) => {
  const request = () => ({ type: DOWNLOAD_DEFINITION_REQUEST });
  const success = (data) => ({ type: DOWNLOAD_DEFINITION_SUCCESS, data });
  const failure = (error) => ({ type: DOWNLOAD_DEFINITION_FAILURE, error });

  console.log("-------------------" + selectedFilter);

  return dispatch => {
    dispatch(request());

    const token = JSON.parse(sessionStorage.user).data.access_token;
    const tenantName = localStorage.getItem('tenantName');

    const url = `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/exportDataFile/${fileDefinition.fileDefinitionId}?filter=${selectedFilter}`;
    let fileName = `${fileDefinition.fileDefinitionName}.${fileDefinition.outputFormat}`;
    
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        dispatch(success(response));

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        toast.error("Download Failed.");
        dispatch(failure(error));
      });
  };
}

export const exportValidationErrors= fileDefinition => {
    const request = () => ({ type: DOWNLOAD_DEFINITION_REQUEST });
    const success = (data) => ({ type: DOWNLOAD_DEFINITION_SUCCESS, data });
    const failure = (error) => ({ type: DOWNLOAD_DEFINITION_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/exportValidationErrors/${fileDefinition.fileDefinitionId}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob', //important
        })
        .then(function (response) {
            if (response.status === 200) {
                const filename= `${fileDefinition.fileDefinitionName}_errors.csv`;
                dispatch(success(response));
                // fileDownload(response.data, filename);

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Download Failed.");
        });

    };
}

export const downloadDefinitionCatalog= (fileDefinition, connectorData) => {
    const request = () => ({ type: DOWNLOAD_DEFINITION_REQUEST });
    const success = (data) => ({ type: DOWNLOAD_DEFINITION_SUCCESS, data });
    const failure = (error) => ({ type: DOWNLOAD_DEFINITION_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/exportCatalogDataFile/${fileDefinition.fileDefinitionId}`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob', //important,
            data: connectorData
        })
        .then(function (response) {
            if (response.status === 200) {
                let filename;
                if(fileDefinition.connectorAdapterDTO && (fileDefinition.connectorAdapterDTO.connector === "ARIBA" && fileDefinition.connectorAdapterDTO.adapter === "NON_CMS_CATALOG")) {
                    if(connectorData.catalogType === "CIF")
                        filename= `${fileDefinition.fileDefinitionName}.cif`;
                    else
                        filename= `${fileDefinition.fileDefinitionName}.xls`;
                } else {
                    filename= `${fileDefinition.fileDefinitionName}.${fileDefinition.outputFormat}`;
                }
                dispatch(success(response));
                // fileDownload(response.data, filename);

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Download Failed.");
        });

    };
}

export const clearSelectedRow= () => {
    return {
        type: CLEAR_SLECTED_ROW
    }
}

export const getUploadedFilesList= projectDefinitionId => {
    const request = () => ({ type: GET_UPLOADED_FILES_LIST_REQUEST });
    const success = (data) => ({ type: GET_UPLOADED_FILES_LIST_SUCCESS, data });
    const failure = (error) => ({ type: GET_UPLOADED_FILES_LIST_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/uploadHistory/${projectDefinitionId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
                console.log("uploaded files list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}

export const getANImportLog = projectDefinitionId => {
    const request = () => ({ type: GET_AN_IMPORT_LOG_REQUEST });
    const success = (data) => ({ type: GET_AN_IMPORT_LOG_SUCCESS, data });
    const failure = (error) => ({ type: GET_AN_IMPORT_LOG_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token = JSON.parse(sessionStorage.user).data.access_token;
        const tenantName = localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/adapter/retrieveAribaImportLog/${projectDefinitionId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    dispatch(success(response.data));
                    console.log("AN Import Log= ", JSON.stringify(response.data));
                }
            })
            .catch(function (error) {
                console.log("error getting AN Import Log");
                dispatch(failure(error));   // network failure
            });

    };
}

export const onClickEditFile= selectedFile => {
    return {
        type: MAKE_FILE_ATTRIBUTES_EDITABLE,
        selectedFile: selectedFile
    }
}

export const uploadedFileAttrEdit= (selectedFile, attr) => {
    return {
        type: UPLOADED_FILE_ATTR_EDIT,
        selectedFile: selectedFile,
        attr: attr
    }
}

export const uploadedFileUpdate= (fileDefinitionId, selectedFile) => {
    const request = () => ({ type: UPLOADED_FILE_UPDATE_REQUEST  });
    const success = (data) => ({ type: UPLOADED_FILE_UPDATE_SUCCESS, data, selectedFile });
    const failure = (error) => ({ type: UPLOADED_FILE_UPDATE_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/uploadHistory/update/${fileDefinitionId}`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                "batchLineCount": selectedFile.batchLineCount || null,
                "charsetName": selectedFile.charsetName || null,
                "currentAzureFileName": selectedFile.currentAzureFileName || null,
                "customDelimiter": selectedFile.delimiterType === 'SpecifyDelimiter' ? (selectedFile.customDelimiter || selectedFile.customDelimiter == "" ? selectedFile.customDelimiter : ',' ): null,
                "delimiterType": selectedFile.delimiterType || null,
                "fileName": selectedFile.fileName || null,
                "fileType": selectedFile.fileType || null,
                "id": selectedFile.id || null,
                "loadOperation": selectedFile.loadOperation || null,
                "timeCreated": selectedFile.timeCreated || null,
                "uploadedBy": selectedFile.uploadedBy || null
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("update uploaded file response= ", JSON.stringify(response));
                dispatch(success(response))
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("update uploaded file error1", error);
        });
    }   
}

export const cancelUploadedFileUpdate= selectedFile => {
    return {
        type: CANCEL_UPLOADED_FILE_UPDATE,
        selectedFile
    }
}

export const uploadedFileDeleteSuccess= (selectedFile) => {
    return {
        type: UPLOADED_FILE_DELETE_SUCCESS,
        data: [{...selectedFile}]
    }
}

export const uploadedFileDeleteRequest= () => {
    return {
        type: UPLOADED_FILE_DELETE_REQUEST,
    }
}

export const uploadedFileDeleteError= (selectedFile) => {
    return {
        type: UPLOADED_FILE_DELETE_FAILURE,
        data: [{...selectedFile}]
    }
}
// export const uploadedFileDelete= (fileDefinitionId, selectedFile) => {
//     const request = () => ({ type: UPLOADED_FILE_DELETE_REQUEST  });
//     const success = (data) => ({ type: UPLOADED_FILE_DELETE_SUCCESS, data });
//     const failure = (error) => ({ type: UPLOADED_FILE_DELETE_FAILURE, error });

//     return dispatch => {
//         dispatch(request());
//         const token= JSON.parse(sessionStorage.user).data.access_token;
//         const tenantName= localStorage.getItem('tenantName');

//         axios({
//             url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/uploadHistory/delete/${fileDefinitionId}/${selectedFile.id}`,
//             method: 'DELETE',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`
//             }
//         })
//         .then(function (response) {
//             if (response.status === 200) {
//                 dispatch(success(response));
//                 toast.success(`${response[0].fileName} file is deleted successfully!`);
//             }
//         })
//         .catch(function (error) {
//             dispatch(failure(error));   // network failure
//             toast.error(error);
//         });
//     };
// }

export const uploadedFileDownload= (fileDefinitionId ,selectedFile) => {
    const request = () => ({ type: UPLOADED_FILE_DOWNLOAD_REQUEST  });
    const success = (data) => ({ type: UPLOADED_FILE_DOWNLOAD_SUCCESS, data });
    const failure = (error) => ({ type: UPLOADED_FILE_DOWNLOAD_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/uploadHistory/getDownloadSAS/${fileDefinitionId}/${selectedFile.id}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(async function (response) {
            if (response.status === 200) {
                // console.log("get SAS= ", JSON.stringify(response.data));

                // Create a new BlobServiceClient
                const blobServiceClient = new BlobServiceClient(response.data.sasURL);

                // Get a container client from the BlobServiceClient
                const containerClient = blobServiceClient.getContainerClient(response.data.containerName);

                let tempArr= response.data.filePath.split('/');
                const newFileName= tempArr[tempArr.length -1];
                tempArr.pop();
                const blobName= `${tempArr.join('/')}/${newFileName}`;
                const blockBlobClient = containerClient.getBlockBlobClient(blobName);
                const downloadBlockBlobResponse = await blockBlobClient.download(0);
                
                dispatch(success(response));

                const downloadUrl = window.URL.createObjectURL(new Blob([await downloadBlockBlobResponse.blobBody]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', newFileName); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch(function (error) {
            console.log("error get SAS");
            dispatch(failure(error));   // network failure
        });

    };
}

export const getSupportedEncodings= () => {
    const request = () => ({ type: GET_SUPPORTED_ENCODINGS_REQUEST  });
    const success = (data) => ({ type: GET_SUPPORTED_ENCODINGS_SUCCESS, data });
    const failure = (error) => ({ type: GET_SUPPORTED_ENCODINGS_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/common/supportedEncodings`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(async function (response) {
            if (response.status === 200) {
                console.log("supported encodings = ", response.data);
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            console.log("error get supported encodings = ", error );
            toast.error(`Supported encoding error: ${error} `);
            dispatch(failure(error));   // network failure
        });

    };
}
